import React from "react";
import { useContext, useState } from "react";
import { ThemeContext } from "../../context/themeContext";
import Switch from "react-switch";
import { BsMoonStarsFill } from "react-icons/bs";
import { MdLightMode } from "react-icons/md";
import pdf from "../../assets/resume.pdf";
import { CgMenuRight } from "react-icons/cg";
import { FaRegFileLines } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

function ToggleThemeButton({ theme, setTheme }) {
  return (
    <div className="">
      <Switch
        onChange={() => {
          setTheme(theme === "light" ? "dark" : "light");
        }}
        height={25}
        handleDiameter={21}
        offColor="#FF7A5C"
        onColor="#161616"
        offHandleColor="#efeeee"
        onHandleColor="#efeeee"
        activeBoxShadow="0 0 0 0"
        checkedIcon={
          <div className="dark-icon">
            <BsMoonStarsFill />
          </div>
        }
        uncheckedIcon={
          <div className="light-icon">
            <MdLightMode />
          </div>
        }
        checked={theme === "light" ? true : false}
      />
    </div>
  );
}

function Resume() {
  return (
    <a
      className="resume-button menu-item"
      href={pdf}
      target="_blank"
      rel="noreferrer"
      download="resume.pdf"
    >
      Resume
      <FaRegFileLines className="website-icon" />
    </a>
  );
}

function MenuButton({ toggleMenu, onClick }) {
  return (
    !toggleMenu && (
      <button className="menu-button text" onClick={onClick}>
        <CgMenuRight />
      </button>
    )
  );
}

function CloseMenuButton({ toggleMenu, onClick }) {
  return (
    toggleMenu && (
      <button className="menu-close-button text" onClick={onClick}>
        <IoClose />
      </button>
    )
  );
}

function Navbar() {
  const { theme, setTheme } = useContext(ThemeContext);
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className={theme}>
      <nav className="navbar">
        <h1 className="text logo">
          Mouaad Lasritene<span className="logo-dot">.</span>
        </h1>

        <MenuButton
          toggleMenu={toggleMenu}
          onClick={() => setToggleMenu(true)}
        />

        {toggleMenu && (
          <div className="menu-wall">
            <div className="menu-list slide-in">
              <CloseMenuButton
                toggleMenu={toggleMenu}
                onClick={() => setToggleMenu(false)}
              />

              <Resume />
              <ToggleThemeButton theme={theme} setTheme={setTheme} />
            </div>
          </div>
        )}

        <ul className="text">
          <Resume />
          <ToggleThemeButton theme={theme} setTheme={setTheme} />
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
